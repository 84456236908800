import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

export class VignetteDataStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "VignetteDataStore",
      properties: ["orderId", "category", "type", "carNo", "startDate", "endDate", "email", "consents", "userData"],
      storage: typeof document !== "undefined" ? window.localStorage : null
    });
  }

  reset = () => {
    this.orderId = "";
    this.category = "";
    this.type = "";
    this.carNo = "";
    this.startDate = "";
    this.endDate = "";
    this.email = "";
    this.consents = [];
  };

  async set(name, value) {
    this[name] = value;
  }

  orderId = "";

  category = "";

  type = "";

  carNo = "";

  startDate = "";

  endDate = "";

  email = "";

  consents = [];

  userData = [];
}

const vignetteData = new VignetteDataStore();
export default vignetteData;
