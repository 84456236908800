import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

export class PaymentStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "PaymentStore",
      properties: ["policyId", "duePayment", "reccPmntID", "reccPmntExpiry", "insurerId", "isMyPos"],
      storage: typeof document !== "undefined" ? window.localStorage : null
    });
  }

  policyId = "";

  insurerId = "";

  duePayment = "";

  reccPmntID = "";

  reccPmntExpiry = "";

  isMyPos = "";

  requestId = "";

  paymentId = "";

  isPolicyPending = false;

  async set(name, value) {
    this[name] = value;
  }

  reset() {
    this.policyId = "";
    this.requestId = "";
    this.paymentId = "";
    this.duePayment = "";
    this.reccPmntID = "";
    this.reccPmntExpiry = "";
    this.insurerId = "";
    this.isMyPos = "";
    this.isPolicyPending = false;
  }
}
const payment = new PaymentStore();
export default payment;
