import { useState, useEffect } from "react";

const useBreakpoints = () => {
  const isClient = typeof window === "object";

  const [breakpoint, setBreakpoint] = useState(() => {
    if (!isClient) return "desktop";

    const width = window.innerWidth;
    if (width < 768) {
      return "mobile";
    }
    if (width < 1024) {
      return "tablet";
    }
    return "desktop";
  });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setBreakpoint("mobile");
      } else if (width < 1024) {
        setBreakpoint("tablet");
      } else {
        setBreakpoint("desktop");
      }
    };

    if (isClient) {
      handleResize();
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [isClient]);

  return {
    isMobile: breakpoint === "mobile",
    isTablet: breakpoint === "tablet",
    isDesktop: breakpoint === "desktop",
    currentBreakpoint: breakpoint
  };
};

export default useBreakpoints;
