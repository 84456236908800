import React from "react";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import { GATSBY_CONFIGURATION } from "../../config/activeProducts";
import Notifications from "../common/Notifications";
import LazySVGS from "../../images/LazySVGS";
import { Colors } from "../../styles/colors";
import useBreakpoints from "../../hooks/useBreakpoints";
import BubbledExclamation from "../../images/component-svg/BubbledExclamation";

const LayoutError = ({ errorTitle, errorMessage = "Опитай отново!", redirectPathErrorMsg, redirectActionErrorMsg, errorBtnTxt, hideErrorBtn }) => {
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation();

  return (
    <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
      {GATSBY_CONFIGURATION === "boleron" && <BubbledExclamation style={{ marginTop: 40, marginBottom: 25 }} />}

      {GATSBY_CONFIGURATION === "vivacom" && (
        <div className="is-flex is-align-items-center is-justify-content-center mt-6 mb-6" style={{ color: Colors.WHITE }}>
          <LazySVGS svg="ErrorVivacom" className="svg-error-vivacom" />
        </div>
      )}
      <div />

      <span className="error-content-title">{errorTitle || "Опитай пак"}</span>

      <Notifications
        classes="notification-default"
        svgIcon="ExclamationMark"
        message={errorMessage}
        type="is-primary"
        subType="is-light"
        maxWidth={isMobile ? 340 : 480}
        marginBottom={15}
        marginTop={40}
      />

      {!hideErrorBtn && (
        <button
          type="button"
          className="button is-info is-rounded error-back-btn theme-button"
          onClick={() => {
            if (redirectActionErrorMsg) {
              redirectActionErrorMsg();
            } else if (redirectPathErrorMsg) {
              navigate(redirectPathErrorMsg, { replace: true });
            } else {
              navigate(-1);
            }
          }}
        >
          <span className="is-uppercase" style={{ fontSize: 14 }}>
            {errorBtnTxt || t("Back", "Назад")}
          </span>
        </button>
      )}
    </div>
  );
};

export default LayoutError;
