import React from "react";
import "@styles/components/loading-element.sass";

const BoleronLoader = ({ isSmall, wrapperClasses }) => (
  <div className={`loader-wrapper is-active ${wrapperClasses || ""}`}>
    <div className={`lds-spinner ${isSmall ? "small" : "pt-1"}`}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default BoleronLoader;
