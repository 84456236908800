import { makePersistable } from "mobx-persist-store";
import { makeAutoObservable } from "mobx";
import _set from "lodash.set";

import { getCookie, removeSpacesFromString } from "../utils/functions";
import InsurerService from "../services/InsurerService";

const allWorkflowProps = {
  id: "",
  ages: "",
  traQuotationId: "",
  traRequestId: "",
  territory: "",
  limitId: "",
  requestId: "",
  groupRequestId: "",
  purpose: 1,
  adults: 1,
  children: 0,
  adults65: 1,
  children18: 0,
  adults74: 0,
  children4: 0,
  policyYears: 0,
  fromDate: null,
  toDate: null,
  fromDate2: null,
  toDate2: null,
  memDates: null,
  memDates2: null,
  days: 0,
  months: 0,
  insurer: "",
  insurerName: "",
  firstName: "",
  corporatePeopleCount: 0,
  middleName: "",
  lastName: "",
  firstNameLatin: "",
  middleNameLatin: "",
  lastNameLatin: "",
  isTitular: true,
  isGoLoaded: false,
  product: "",
  title: "",
  package: null,
  packagePrice: "",
  packagePricePerMonth: "",
  packagePricePerMonthEUR: "",
  packagePriceWithoutTax: "",
  packagePricePerMonthWithoutTaxEUR: "",
  taxMonthEur: "",
  taxMonthBGN: "",
  promoCode: "",
  promoCodePercentage: 0,
  promoCodes: [],
  promoInsurer: "",
  promoProduct: "",
  cashbackAmount: 0,
  egn: "",
  email: "",
  address: "",
  city: "",
  marketingConsent: false,
  cityData: {},
  citizenship: "",
  postCode: "",
  //cityPostCode: "",
  //cityFormatedString: "",
  phone: "",
  limit: "",
  persons: [],
  cards: [],
  lfiProfitability: "",
  prpSize: "",
  prpStreet: "",
  prpStreetNumber: "",
  prpCity: "",
  prpCityPostCode: null,
  prpCityFormatedString: "",
  prpInsuredAmount: 0,
  insuredAmountPersonal: "",
  prpEtage: "",
  prpAppartment: "",
  prpResidentialBlock: "",
  prpEntrance: "",
  prpFullFormattedAddress: "",
  prpAddress: "",
  prpType: 0,
  prpDesc: 0,
  prpGo: "",
  prpElec: "",
  dvizhimo: "",
  dvizhimoZs: "",
  kuso: "",
  selectedGo: false,
  selectedKuso: false,
  kusoZs: "",
  age: "",
  favorPersons: [],
  go: "",
  goZs: "",
  installments: "",
  mtnGoal: 0,
  basePrice: "",
  lkDate: "",
  lkNum: "",
  lkIssuedFrom: "",
  lkIssuedDate: "",
  addressDelivery: "",
  cityDelivery: "",
  cityDeliveryData: { id: 0, label: "" },
  officeDelivery: "",
  officeDeliveryData: { id: 0, label: "" },
  userDelivery: "",
  phoneDelivery: "",
  messageDelivery: "",
  deliveryCompany: "",
  deliveryWay: "",
  deliveryType: "",
  deliveryData: {},
  datesOfBirth: "",
  numberChildren: "", // todo to be removed
  choice: "",
  surface: "",
  premium: 0,
  tax: 0,
  total: 0,
  totalBeforeAllianzDiscount: 0, // Case (BOL-4102): Allianz Travel
  basePremium: 0,
  carNo: "",
  brand: "",
  model: "",
  carCity: "",
  questions: [],
  declarations: {},
  flow: "",
  cscFlow: "", // todo flow-a дали е за един застраховател или за много като при гражданската
  cscQuestions: [],
  cscAdditionalQuestions: [],
  cscCarPrice: 26500,
  cscMinPrice: "",
  cscMaxPrice: "",
  cscMinPrice2: "",
  cscMaxPrice2: "",
  cscShowCustomPrice: false,
  cscPricingType: "",
  cscCarPrices: {},
  cscCarYears: 10,
  cscPercentage: "",
  cscPercentages: [],
  cscBasePrice: "",
  cscIsNew: "Y",
  cscFinalPrice: "",
  cscService: "",
  cscServices: {},
  cscFinalPriceWithDZP: "",
  cscRamaNumber: "",
  cscRegNumber: "",
  cscTalon: "",
  cscDateOfRegistration: "",
  cscMake: "",
  cscModel: "",
  cscVariant: "",
  cscBuildYear: "",
  cscEngineDisplacement: "",
  cscPower: "",
  cscPolicyId: "",
  cscEngine: "",
  cscGearbox: "",
  cscDoors: "",
  cscBodyType: "",
  cscVinDecoded: true,
  cscRenewalDate: "",
  cscRenewalInsurerName: "",
  cscBulstradPolicyId: "",
  cscHasActivePolicy: false,
  cscPriceObj: {},
  cscPriceOffer: {},
  skipInsurerInfo: false,
  cscIsManual: false,
  isRisky: false,
  cscIsOffer: false,
  prices: {},
  promoPrice: 0,
  goRegNumber: "",
  goExperience: "",
  goTalon: "",
  goAge: "",
  goSteeringWheel: false,
  talonFrontSrc: "",
  talonBackSrc: "",
  talonBigSrc: "",
  talonFrontName: "",
  talonBackName: "",
  talonBigName: "",
  talonFrontExtension: "",
  talonBackExtension: "",
  talonBigExtension: "",
  policyId: "",
  duePaymentId: "",
  dataInternal: null, // данни за ГО от нашата база, не от застрахователя
  goPolicyNumber: "", // полето е нужно при плащане на следващи вноски
  goInstallmentNo: "", // пореден номер на самата вноска
  goDueDate: "", // дата на падеж на вноската
  goCoveredFrom: "",
  goCoveredTo: "",
  goIssueDate: "", // дата на издаване на полицата
  goRenewalDate: "", // todo new one
  goRenewalInsurerName: "", // todo new one
  isOurClient: false,
  paymentMode: 1, // 1 - с карта, 2 - наложен платеж, 3 - myPos
  genEgn: "", // използва се само за GO и CSC
  payments: [],
  allPayments: [], // използва се само за CSC+GO в случай cscPricingType === "OfferWithSamplePrice
  goPayments: [],
  cscPayments: [],
  goPaymentsInstallments: [],
  personType: 2, // 2 Физическо лице 1 - Юридическо лице
  eik: "",
  companyName: "",
  companyAndManagerData: "",
  companyOwnersDskDeclarationData: "",
  companyOwnersDskDeclarationOwnersCount: "",
  companyWorkers: "",
  mockCompanyWorkers: "",
  mockCompanyPriceObj: "",
  ciAgeGroupsCounter: "",
  policyCompanyWorkers: "",
  isManagerAgreement: true,
  companyOwnersDskDeclarationDatapart2: "",
  companyOwnersDskDeclarationDatapart2FormConfig: "",
  selectedCountries: [],
  selectedCountriesFullNames: [],
  isRenewal: false,
  isOffer: false,
  utmSource: "",
  utmMedium: "",
  utmCampaign: "",
  firstNameTitular: "",
  middleNameTitular: "",
  lastNameTitular: "",
  egnTitular: "",
  make: "",
  dateType: 1,
  customerConfirmation: "",
  documentIdM: "",
  duePaymentIdDocument: "",
  isPaymentConfirmation: "",
  duePaymentAmount: 0,
  goPaymentRejectionQuestionnaireOption: "",
  vinNumber: "",
  hisOption: "",
  vigEmail: "",
  vigRegNumber: "",
  paymentId: 0,
  priceObj: {},
  beneficiary: 0,
  originOfFunds: undefined,
  uniqaHealthPersonsData: "",
  healthPriceMonth: "",
  healthPriceYear: "",
  healthPriceObj: false,
  isWorkflowSwitched: false,
  disclaimerObj: false,
  discountRate: 0, // използва се само за CSC и CSC+GO за момента и се сетва в CascoPrice,
  referralId: ""
};

for (let i = 1; i < 10; i++) {
  allWorkflowProps[`firstName${i}`] = "";
  allWorkflowProps[`lastName${i}`] = "";
  allWorkflowProps[`middleName${i}`] = "";
  allWorkflowProps[`egn${i}`] = "";
  allWorkflowProps[`firstNameLatin${i}`] = "";
  allWorkflowProps[`lastNameLatin${i}`] = "";
  allWorkflowProps[`middleNameLatin${i}`] = "";
  allWorkflowProps[`allAnsweredWithNo${i}`] = false;
  // allWorkflowProps[`age${i}`] = "";
}

for (let i = 1; i < 11; i++) {
  allWorkflowProps[`age${i}`] = "";
}

export class WorkflowStore {
  constructor() {
    this.setDefaultData();
    makeAutoObservable(this);
    makePersistable(this, {
      name: "WorkflowStore",
      properties: Object.keys(allWorkflowProps),
      storage: typeof document !== "undefined" ? window.localStorage : null
    });
  }

  referralId = "";

  setDefaultData() {
    for (const key in allWorkflowProps) {
      if (allWorkflowProps.hasOwnProperty(key) && !["promoCodes", "referralId"].includes(key)) {
        this[key] = allWorkflowProps[key];
      }
    }
  }

  get nbPersons() {
    return this.adults + this.children;
  }

  get person() {
    if (this.insurer === "euroins") {
      return `${removeSpacesFromString(this.firstName)} ${removeSpacesFromString(this.middleName)} ${removeSpacesFromString(this.lastName)}`;
    }
    return `${this.firstName} ${this.middleName} ${this.lastName}`;
  }

  set(name, value) {
    this[name] = value;
  }

  setDeep(propertyPath, value) {
    _set(this, propertyPath, value);
  }

  async init(product, title, insurerCode, flow = null) {
    this.setDefaultData();
    this.product = product;
    this.title = title;
    this.months = ["PRP", "GO", "CSC", "CSC+GO"].includes(product) ? 12 : 0;
    this.days = product === "TRA" ? 3 : 0;
    this.installments = ["HLT"].includes(product) ? 12 : 1;
    this.payments = [];
    if (product === "KID") {
      this.children = 1;
    }
    this.promoCode = "";
    this.promoPrice = 0;
    this.cashbackAmount = 0;

    this.insurer = insurerCode;
    this.goPolicyNumber = "";
    this.insurerName = InsurerService.getInsurerName(insurerCode, product);
    this.dateType = product === "GO" ? 1 : 2;
    this.personType = 2;
    const boleronCookie = getCookie("boleron_affiliate") ? JSON.parse(atob(getCookie("boleron_affiliate"))) : null;
    if (boleronCookie) {
      const { utm_source, utm_medium, utm_campaign } = boleronCookie;
      this.utmSource = utm_source;
      this.utmMedium = utm_medium;
      this.utmCampaign = utm_campaign;
    }
    if (flow) {
      this.flow = flow;
    } else {
      this.flow = `normalFlow${product}`;
    }
  }

  reset() {
    this.setDefaultData();
    this.referralId = "";
  }
}

const workflow = new WorkflowStore();
export default workflow;
