import { useState, useEffect } from "react";

const useViewPoint = () => {
  const [viewPoint, setviewPoint] = useState({
    visibleHeight: 0,
    visibleTop: 0,
    visibleBottom: 0
  });

  useEffect(() => {
    const handleScroll = () => {
      const viewPointHeight = window.innerHeight;
      const scrollTop = window.scrollY;

      const documentHeight = document.documentElement.scrollHeight;
      const visibleHeight = Math.min(viewPointHeight, documentHeight - scrollTop);
      const visibleTop = Math.max(0, scrollTop);
      const visibleBottom = Math.min(documentHeight, scrollTop + viewPointHeight);

      setviewPoint({
        visibleHeight,
        visibleTop,
        visibleBottom
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return viewPoint;
};

export default useViewPoint;
