import React from "react";
import Collapsible from "react-collapsible";

const Collapsibe = ({ title, children, isOpened, navbar, key }) => {
  const PsudoElementStyle = {
    after: { fontWeight: "normal", content: "-" }
  };

  return (
    <Collapsible
      key={key}
      id="react-collapsible"
      triggerElementProps={{
        id: "collapsible-trigger-id"
      }}
      contentElementId="collapsible-content-id"
      trigger={
        <div style={navbar ? { padding: 0 } : {}}>
          <h4
            data-cy-title=""
            className={navbar ? "regular-txt is-size-4 p-0" : ""}
            style={
              navbar
                ? {
                    ...PsudoElementStyle
                  }
                : {}
            }
          >
            {title}
          </h4>
          {/* <DoubleArrowIcon /> */}
        </div>
      }
      open={isOpened}
    >
      {children}
    </Collapsible>
  );
};

export default Collapsibe;
