import React from "react";

const BackButton = () => (
  <svg height="24px" viewBox="0 0 24 18.8" fill="#fff">
    <path
      d="M23,8.2L23,8.2l-19.6,0L9,2.1c0.5-0.5,0.5-1.2,0-1.7C8.7,0.1,8.4,0,8.1,0C7.8,0,7.5,0.1,7.2,0.4c0,0,0,0,0,0.1
	L0.6,7.7c-0.9,1-0.9,2.4,0,3.4l6.5,7.3c0.2,0.2,0.5,0.4,0.9,0.4c0.3,0,0.6-0.1,0.9-0.4C9.3,18,9.3,17.3,9,16.8l-5.5-6.2h19.4
	c0.3,0,0.6-0.1,0.8-0.4C23.9,10,24,9.7,24,9.4C24,8.8,23.6,8.3,23,8.2z"
    />
  </svg>
);

export default BackButton;
