import React from "react";
import { Colors } from "../../styles/colors";

const BubbledExclamation = ({ className, width = 80, height = 73, style }) => (
  <svg className={className} style={style} width={width} height={height} viewBox="0 0 79.629 71.865">
    <g transform="translate(-922.371 -181)">
      <circle fill={Colors.PRIMARYLIGHT} cx="34" cy="34" r="34" transform="translate(934 181)" />
      <g transform="translate(922.371 186.405)">
        <path
          fill={Colors.PRIMARY}
          d="M2860.323,606.157a4.423,4.423,0,0,0-.565-.632L2852,597.771c-.113-.113-.239-.238-.31-.32.063-.088.173-.222.289-.364a32.5,32.5,0,0,0,7.409-26.7,31.742,31.742,0,0,0-11.04-19.768,33.231,33.231,0,0,0-53.86,16.647,44.633,44.633,0,0,0-.993,5.505l0,.03,0,5.782c.068.433.129.868.189,1.3.133.957.271,1.948.493,2.915a33,33,0,0,0,11.133,18.286,31.705,31.705,0,0,0,20.423,7.744c4.455.05,8.952.066,13.417.066,3.268,0,6.519-.008,9.724-.016,3.254-.007,6.509-.015,9.746-.015.147,0,.293,0,.439-.009a1.7,1.7,0,0,0,1.258-2.694Zm-11.206-10.986c-.173.2-.357.4-.544.593l-.237.249a1.856,1.856,0,0,0-.434.462,1.822,1.822,0,0,0,.445,2.5l6.46,6.456q-4.192,0-8.383.012c-6.7.013-13.619.03-20.427-.042a28.585,28.585,0,0,1-18.772-7.192,29.751,29.751,0,0,1,10.177-50.843,27.449,27.449,0,0,1,8.914-1.488q1.28,0,2.569.119a29.4,29.4,0,0,1,22.909,13.812,27.815,27.815,0,0,1,4.385,12.426A29.449,29.449,0,0,1,2849.117,595.171Z"
          transform="translate(-2793.496 -542.432)"
        />
        <path
          fill={Colors.PRIMARY}
          d="M2821.261,562.427a30.15,30.15,0,0,0,.38,4.095,49.939,49.939,0,0,0,1.94,9.327,5.48,5.48,0,0,0,1.247,2.235,1.718,1.718,0,0,0,.156.156,1.873,1.873,0,0,0,1.393.481,1.911,1.911,0,0,0,1.323-.644,5.506,5.506,0,0,0,1.272-2.314,59.97,59.97,0,0,0,2.288-13.08l0-.271c.005-.108.007-.21.005-.313a4.939,4.939,0,0,0-4.933-4.858c-.044,0-.09,0-.128,0-.089,0-.171,0-.251.006a4.94,4.94,0,0,0-4.7,5.179Zm4.964-2.41a2.255,2.255,0,0,1,2.259,2.277,56.542,56.542,0,0,1-1.726,10.71l-.1.384h-.8l-.1-.387a59.929,59.929,0,0,1-1.722-10.553A2.3,2.3,0,0,1,2826.225,560.017Z"
          transform="translate(-2792.681 -541.997)"
        />
        <path
          fill={Colors.PRIMARY}
          d="M2821.252,586.47v.151a4.933,4.933,0,0,0,5,4.873h.14a4.947,4.947,0,0,0,4.863-5.021v-.152a4.935,4.935,0,0,0-4.931-4.827h-.214A4.92,4.92,0,0,0,2821.252,586.47Zm5.022-2.2a2.327,2.327,0,0,1,2.157,1.871l.071.027-.024.379a2.229,2.229,0,1,1-2.2-2.277Z"
          transform="translate(-2792.681 -541.285)"
        />
      </g>
    </g>
  </svg>
);
export default BubbledExclamation;
