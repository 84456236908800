import { default as boleron } from "./boleron";
import { default as vivacom } from "./vivacom";
import { GATSBY_CONFIGURATION } from "../activeProducts";

let urls = boleron;
if (GATSBY_CONFIGURATION === "vivacom") {
  const objWithoutUndefinedValues = Object.fromEntries(Object.entries(vivacom).filter(([k, v]) => v !== undefined));
  urls = Object.assign(boleron, objWithoutUndefinedValues);
}
export default { ...urls };
