import { useState, useEffect } from "react";

const useClientServerContext = () => {
  const [isClient, setIsClient] = useState(false);
  const [isServer, setIsServer] = useState(false);

  useEffect(() => {
    setIsClient(typeof window !== "undefined");
    setIsServer(typeof window === "undefined");
  }, []);

  return { isClient, isServer };
};

export default useClientServerContext;
