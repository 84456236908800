import activeProducts from "./index";

export const getHeaderLogo = () => activeProducts?.header?.logo;
export const getFooterLogo = () => activeProducts?.footer?.logo;

export const getHeaderLink = id => activeProducts?.header?.links?.find(x => x.id === id);
export const showHeaderLink = id => getHeaderLink(id) && !getHeaderLink(id)?.disabled;

export const getFooter = () => activeProducts?.footer;
export const getHeader = () => activeProducts?.header;

export const isOfflineOfferPermited = product => activeProducts?.mainProducts?.find(x => x.id === product)?.offlineOffer;

export const getActiveInsurersFromConfig = product =>
  activeProducts?.mainProducts
    ?.find(x => x.id === product)
    ?.insurers?.filter(i => !i.disabled)
    ?.map(i => i.id);

export const getActiveProductsFromConfig = () => activeProducts?.mainProducts.filter(p => !p.disabled);

export const getCouriers = () => activeProducts?.tenantSettings?.couriers;

export const getActiveInsurersWithCSCPricesFromConfig = product =>
  activeProducts?.mainProducts
    ?.find(x => x.id === product)
    ?.insurers?.filter(i => !i.disabled && i.hasPrice)
    ?.map(i => i.id);

export const getDocumentsObj = (product, insurer, productObj = "mainProducts") =>
  activeProducts?.[productObj]
    ?.find(x => x.id === product)
    ?.insurers?.find(x => x.id === insurer)
    ?.documents?.reduce((obj, item) => ({ ...obj, [item.id]: item.url }), {});

export const hasPromoCode = (product, insurer, productObj = "mainProducts") =>
  activeProducts?.[productObj]?.find(x => x.id === product)?.insurers?.find(x => x.id === insurer)?.hasPromoCode;

export const hasMoreThanOnePaymentMethod = (product, insurer) =>
  activeProducts?.mainProducts?.find(x => x.id === product)?.insurers?.find(x => x.id === insurer)?.paymentModes?.length > 1;

export const getPaymentModes = (product, insurer) => {
  const paymentModes = activeProducts?.mainProducts?.find(x => x.id === product)?.insurers?.find(x => x.id === insurer)?.paymentModes;
  if (paymentModes) {
    return paymentModes;
  }
  return ["1"]; // 1 - с карта, 2 - наложен платеж, 3 - Iris
};

export const bgPhonePrefix = "+359";

export const isCustomCarPriceEnabled = activeProducts?.isCustomCarPriceEnabled;
