import React from "react";
import classNames from "classnames";

import { getHeader } from "../../config/activeProducts/helpers";
import useScrollDirection from "../../hooks/useScrollDirection";
import useViewPoint from "../../hooks/useViewPoint";
import Navigation from "./Navigation";

import "@styles/components/Header.sass";

const Header = ({ auth, body, hideLogoText, title, navbarWorkflow, showBackButton, isWorkflow }) => {
  const { isScrollingUp } = useScrollDirection();
  const { visibleTop } = useViewPoint();

  const headerFromConfig = getHeader();
  const heroClasses = `hero hero-${body ? "extended" : "simple"}-background is-primary is-medium`;
  const heroHeight = 500; // Note : This is the height from which we hide the navbar.
  const isFixedTopVisible = isScrollingUp && visibleTop > heroHeight;

  return (
    <section className={heroClasses}>
      <div className="hero-head header-frame">
        <Navigation
          navClasses={classNames({
            "is-fixed-top": visibleTop > 500,
            "is-fixed-top-visible": isFixedTopVisible
          })}
          title={title}
          auth={auth}
          hideLogoText={hideLogoText}
          showBackButton={showBackButton}
          inWorkflow={navbarWorkflow}
        />
      </div>
      {!headerFromConfig?.hr?.disabled && !isWorkflow && <hr />}
      {body}
    </section>
  );
};
export default Header;
