import carData from "./CarDataStore";
import auth from "./AuthStore";

import workflow from "./WorkflowStore";
import vignetteData from "./VignetteDataStore";
import settings from "./SettingsStore";
import payment from "./PaymentStore";
import stashedProductData from "./StashedProductDataStore";

// const ls = typeof document !== "undefined" ? window.localStorage : null;
// if (ls) {
//  ls.clear();
// }
const stores = {
  carData,
  vignetteData,
  auth,
  workflow,
  settings,
  payment,
  stashedProductData
};

export default stores;
