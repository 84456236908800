import BoleronLoaderSvg from "@icons/Boleron_logo_loader.svg";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";

import GoogleReviewRating from "@images/testemonials/google-review-rating.svg";
import VerticalCarousel from "@components/common/VerticalCarousel";
import GoogleReviewBox from "@components/common/GoogleReviewBox";
import GoogleRating from "@icons/google-rating.svg";

import "../../styles/components/Tooltip.sass";
import "../../styles/components/Layout.sass";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { shuffleArray } from "../../utils/functions";

import useClientServerContext from "../../hooks/useClientServerContext";
import { GATSBY_CONFIGURATION } from "../../config/activeProducts";
import ProductsService from "../../services/ProductsService";
import useBreakpoints from "../../hooks/useBreakpoints";
import LazySVGS from "../../images/LazySVGS";
import BoleronLoader from "./BoleronLoader";
import LayoutError from "./LayoutError";
import Header from "./Header";
import Footer from "./Footer";

export const VARIANT_DEFAULT = "default";
export const VARIANT_WORKFLOW = "workflow";
export const VARIANT_DEFAULT_NO_FOOTER = "defaultNoFooter";
export const VARIANT_WORKFLOW_HIDE_BACK_BUTTON = "workflowNoBackButton";

const Layout = props => {
  const { t } = useTranslation();
  const {
    workflow,
    auth,
    title,
    boxTitle,
    boxTitleStyle,
    boxSubTitle,
    boxSubTitleStyle,
    testimonialsData,
    boxSubTitleHTML,
    centerContent,
    addonStyles = false,
    boxTitleIcon,
    boxTitleIconTooltip,
    dataTooltip,
    btnContinue,
    btnContinueTextSize = "is-size-3-5",
    contentBoxStyles,
    btnContinueTxt,
    continueBtnComponentStyles,
    btnSubmit,
    btnDisabled,
    children,
    continueFunc,
    error,
    isWarningError,
    errorTitle,
    errorMessage,
    errorBtnTxt,
    redirectPathErrorMsg,
    redirectActionErrorMsg,
    titleTooltip,
    loading,
    otherBtn,
    erraseMarginBottom = false,
    multipleLineBoxSubTitle,
    boldedSubTitles = [],
    isExclamationSvg,
    navbarWorkflow,
    variant,
    emptyWorkflowRedirect,
    showSuccessMessage,
    onSuccessAknowledge,
    loadingParams,
    hideErrorBtn
  } = props;

  const [layoutTimeout, setLayoutTimeout] = useState(false);
  const [shuffledTestimonialsData, setShuffledTestimonialsData] = useState(null);

  useEffect(() => {
    if (emptyWorkflowRedirect && workflow && !workflow.flow) {
      navigate("/", { replace: true });
    }

    if (testimonialsData) {
      setShuffledTestimonialsData(shuffleArray(testimonialsData));
    }

    setTimeout(() => setLayoutTimeout(true), 600);
  }, []);

  const { isClient } = useClientServerContext();
  const { isMobile, isDesktop } = useBreakpoints();
  const layoutVariant = typeof window === "undefined" ? VARIANT_DEFAULT : navbarWorkflow ? VARIANT_WORKFLOW : variant || VARIANT_DEFAULT;
  const layoutTitle = title || ProductsService.getProductName(workflow?.product);
  const showBackButton = variant !== VARIANT_WORKFLOW_HIDE_BACK_BUTTON && !showSuccessMessage;
  const hideHeader = isClient && window.sessionStorage.getItem("hideHeader") === "true";

  const seoNoIndexComponent = React.Children.toArray(children).find(child => React.isValidElement(child) && ["SeoNoIndex"].includes(child?.type?.name));

  const otherChildren = React.Children.toArray(children).filter(child => React.isValidElement(child) && child?.type?.name !== "SeoNoIndex");

  const content = (
    <>
      {otherChildren}
      {btnContinue && (
        <div className="has-text-centered">
          <button
            disabled={btnDisabled}
            className={`button is-medium is-info is-rounded btn-continue theme-button ${btnContinueTextSize}`}
            type={btnSubmit ? "submit" : "button"}
            style={continueBtnComponentStyles}
            onClick={continueFunc}
            data-cy-continue-btn
          >
            <span className="is-uppercase" title={btnContinueTxt || "Продължи"}>
              {btnContinueTxt || t("continue", "Продължи")}
            </span>
          </button>
        </div>
      )}
      {otherBtn && otherBtn}
    </>
  );

  const loadingContent = (
    <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center loading-content-wrapper">
      {GATSBY_CONFIGURATION === "boleron" && <BoleronLoaderSvg />}
      <div className="mt-4 has-text-centered" style={{ width: "100%" }}>
        <BoleronLoader />
      </div>
      <span className="mt-4 mb-4 loading-content-text">{loadingParams?.message ? loadingParams.message : "Моля, изчакайте..."}</span>
    </div>
  );

  const errorContent = (
    <LayoutError
      isWarningError={isWarningError}
      isExclamationSvg={isExclamationSvg}
      errorTitle={errorTitle}
      errorMessage={errorMessage}
      redirectPathErrorMsg={redirectPathErrorMsg}
      redirectActionErrorMsg={redirectActionErrorMsg}
      errorBtnTxt={errorBtnTxt}
      hideErrorBtn={hideErrorBtn}
    />
  );

  const successMessageContent = (
    <div className="has-text-centered">
      <LazySVGS svg="Success" className="success-svg" width={73} height={75} style={{ marginTop: 30, marginBottom: 30 }} />
      <p style={{ fontSize: 18 }}>Твоите промени са</p>
      <div className="success-text mb-4">Запазени успешно!</div>
      <div className="has-text-centered">
        <button
          type="button"
          className="button is-info is-rounded success-message-btn"
          onClick={() => {
            if (onSuccessAknowledge) {
              onSuccessAknowledge();
            }
          }}
        >
          <span className="is-uppercase">ОК</span>
        </button>
      </div>
      <button
        className="btn-home-on-success"
        type="button"
        onClick={() => {
          navigate("/");
        }}
      >
        <p className="is-uppercase is-regular-font-family">Начало</p>
      </button>
    </div>
  );

  const renderDefault = () => (
    <>
      {hideHeader && (props.heroBodyMobileApp ? props.heroBodyMobileApp : null)}
      {!hideHeader && (
        <Header body={props.heroBody} auth={auth} hideLogoText={props.hideLogoText} marginBottom={props.marginBottom} navbarWorkflow={props.navbarWorkflow} siteTitle="Home" />
      )}

      <main
        className={props.noMainPadding ? "p-0" : "p-3"}
        style={{
          marginTop: props.mt ? -50 : 0
        }}
      >
        {otherChildren}
      </main>
      {props.variant !== VARIANT_DEFAULT_NO_FOOTER && <Footer />}
    </>
  );

  const renderWorkflow = () => {
    const boxContentContainerPrefix = addonStyles && addonStyles === "fine-check-result" ? `${addonStyles}-` : "";
    const layoutMaxHeight = addonStyles && addonStyles === "car-damage" ? `${addonStyles}-` : "";
    const layoutContentGap = addonStyles && addonStyles === "message-simple" ? `row-gap-24` : "";

    return (
      <>
        {hideHeader ? (
          <div style={{ marginTop: 180 }} />
        ) : (
          <Header siteTitle="Boleron" auth={auth} title={layoutTitle} navbarWorkflow={navbarWorkflow} showBackButton={showBackButton} isWorkflow />
        )}
        <main className="box-container" style={{ position: "relative", ...(erraseMarginBottom ? { marginBottom: 0 } : {}) }}>
          <div
            className={`${layoutMaxHeight}layout-workflow-border-radius boleron-box-margin`}
            style={{
              marginBottom: erraseMarginBottom && 0,
              display: layoutContentGap && "flex",
              ...(isDesktop && testimonialsData ? { minHeight: 915 } : {})
            }}
          >
            {showSuccessMessage ? (
              successMessageContent
            ) : (
              <>
                {loading && layoutTimeout && loadingContent}

                {error && errorContent}

                {!loading && !error && (
                  <>
                    {GATSBY_CONFIGURATION === "boleron" &&
                      !["GO", "CSC", "TRA"].includes(workflow.product) &&
                      (boxTitle === "Оферти" || (boxTitle === "Застрахователна сума" && !["CSC", "CSC+GO"].includes(workflow.product))) && (
                        <div className={`is-flex ${isMobile ? "is-justify-content-center" : "is-justify-content-flex-end"}`}>
                          <GoogleRating style={{ minWidth: 146, minHeight: 43, marginRight: isMobile ? 0 : 25 }} />
                        </div>
                      )}

                    {boxTitle && !boxTitleIconTooltip && (
                      <h1 className="has-text-centered has-text-primary mt-5 boxTitle" style={boxTitleStyle}>
                        {boxTitleIcon}
                        {boxTitle}
                        {titleTooltip}
                      </h1>
                    )}

                    {boxTitle && boxTitleIconTooltip && (
                      <div className="box-title-tooltip-container">
                        <h1 className="has-text-centered has-text-primary mt-5 boxTitle">{boxTitle}</h1>
                        <div data-tooltip={dataTooltip} className="has-tooltip-multiline has-tooltip-left">
                          {boxTitleIconTooltip}
                        </div>
                      </div>
                    )}

                    {boxSubTitle && multipleLineBoxSubTitle ? (
                      <p className="light-text is-size-4 box-subtitle multiline">
                        {boxSubTitle.map((subTitle, index) => (
                          <React.Fragment key={`${subTitle}-${index}`}>
                            <span className={boldedSubTitles.includes(index) ? "is-bold-family" : ""}>{subTitle}</span> <br />
                          </React.Fragment>
                        ))}
                      </p>
                    ) : (
                      boxSubTitle && (
                        <p className="light-text is-size-4 box-subtitle" style={boxSubTitleStyle}>
                          {boxSubTitle}
                        </p>
                      )
                    )}

                    {centerContent ? (
                      <div
                        className={`is-flex is-flex-direction-column is-justify-content-center is-align-items-center box-content-container ${layoutContentGap}`}
                        style={contentBoxStyles}
                      >
                        {!!boxSubTitleHTML && boxSubTitleHTML}
                        {content}
                      </div>
                    ) : (
                      <div
                        className={`is-flex is-flex-direction-column is-justify-content-center is-align-items-center ${boxContentContainerPrefix}box-content-container`}
                        style={contentBoxStyles}
                      >
                        {!!boxSubTitleHTML && boxSubTitleHTML}
                        {content}
                      </div>
                    )}
                  </>
                )}
              </>
            )}

            {GATSBY_CONFIGURATION === "boleron" && isMobile && shuffledTestimonialsData && (
              <div className="is-fullwidth">
                <GoogleReviewRating className="is-fullwidth mx-auto" />

                <VerticalCarousel arrows={false} slidesToShow={1}>
                  {shuffledTestimonialsData.map((data, index) => (
                    <GoogleReviewBox
                      key={index}
                      authorTitle={data.authorTitle}
                      authorImage={data.authorImage}
                      reviewText={data.reviewText}
                      currentLanguage="bg"
                      starType="gold"
                      styleType="vertical"
                    />
                  ))}
                </VerticalCarousel>
              </div>
            )}
          </div>

          {GATSBY_CONFIGURATION === "boleron" && isDesktop && shuffledTestimonialsData && (
            <div className="offers-testimonials-container">
              <div className="google-rating-box">
                <GoogleReviewRating />
              </div>

              <VerticalCarousel arrows={false} slidesToShow={3}>
                {shuffledTestimonialsData.map((data, index) => (
                  <GoogleReviewBox
                    key={index}
                    authorTitle={data.authorTitle}
                    authorImage={data.authorImage}
                    reviewText={data.reviewText}
                    currentLanguage="bg"
                    starType="gold"
                    styleType="vertical"
                  />
                ))}
              </VerticalCarousel>
            </div>
          )}
        </main>
      </>
    );
  };

  return (
    <>
      {seoNoIndexComponent}
      {layoutVariant === VARIANT_WORKFLOW && renderWorkflow()}
      {layoutVariant === VARIANT_WORKFLOW_HIDE_BACK_BUTTON && renderWorkflow()}
      {layoutVariant === VARIANT_DEFAULT && renderDefault()}
      {layoutVariant === VARIANT_DEFAULT_NO_FOOTER && renderDefault({ hideFooter: true })}
      {GATSBY_CONFIGURATION === "vivacom" && (
        <a
          onClick={() => {
            window.Cookiebot.show();
          }}
          id="open-cookie-cons"
          style={{
            position: "fixed",
            right: 0,
            bottom: -5,
            opacity: 0.5,
            zIndex: 20
          }}
        >
          <img src="/png/cookie-open.png" alt="Open cookie bot settings" style={{ width: 50 }} />{" "}
        </a>
      )}
    </>
  );
};

Layout.defaultProps = {
  children: <div />
};

Layout.propTypes = {
  children: PropTypes.node
};

export default inject("auth", "workflow")(observer(Layout));
