import {
  blue,
  green,
  primary,
  primaryLight,
  info,
  white,
  lightGray,
  lighterGray,
  greyLightest,
  gray,
  gold,
  mainPurple,
  darkGray,
  red,
  black,
  yellow,
  orange,
  grayForBorder,
  grayBackground,
  teal,
  checkboxRadioBackground,
  checkboxRadioBackgroundDark,
  link,
  text
} from "./_variables-additional.module.sass";

export const Colors = {
  PRIMARY: primary,
  PRIMARYLIGHT: primaryLight,
  INFO: info,
  GREEN: green,
  GOLD: gold,
  MAIN_PURPLE: mainPurple,
  BLACK: black,
  BLUE: blue,
  WHITE: white,
  LIGHT_GRAY: lightGray,
  LIGHTER_GRAY: lighterGray,
  LIGHTEST_GRAY: greyLightest,
  GRAY: gray,
  DARK_GRAY: darkGray,
  RED: red,
  YELLOW: yellow,
  ORANGE: orange,
  GRAY_FOR_BORDER: grayForBorder,
  GRAY_BACKGROUND: grayBackground,
  TEAL: teal,
  CHECKBOX_RADIO_BACKGROUND: checkboxRadioBackground,
  CHECKBOX_RADIO_BACKGROUND_DARK: checkboxRadioBackgroundDark,
  LINK: link,
  TEXT: text
};
