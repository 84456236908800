import React from "react";
import { Link } from "gatsby-plugin-react-i18next";

export const LanguageSwitcherDesktop = ({ originalPath, languages, currentLanguage }) => (
  <div className="language-switcher is-hidden-touch navbar-item has-dropdown is-hoverable">
    <span className="navbar-link is-uppercase bold-txt">{currentLanguage}</span>
    <div className="navbar-dropdown has-text-centered">
      {languages
        .filter(lng => lng !== currentLanguage)
        .map(lng => (
          <Link key={lng} className="langauge-switch-dropdown-item is-uppercase" to={originalPath} language={lng}>
            {lng}
          </Link>
        ))}
    </div>
  </div>
);

export const LanguageSwitcherMobile = ({ originalPath, languages, currentLanguage }) => (
  <div className="language-switcher-mobile is-hidden-desktop navbar-item lvl-1" style={{ padding: "1rem 2rem" }}>
    {languages.map(lng => (
      <Link key={lng} className={`lang-link is-uppercase ${lng === currentLanguage ? "active has-text-weight-bold" : ""}`} to={originalPath} language={lng}>
        {lng}
      </Link>
    ))}
  </div>
);
