// extracted by mini-css-extract-plugin
export var black = "#000";
export var blue = "#0093dd";
export var checkboxRadioBackground = "#f6f2ff";
export var checkboxRadioBackgroundDark = "#ede8f9";
export var darkGray = "#2e2e2e";
export var gold = "#fa0";
export var gray = "#5b6070";
export var grayBackground = "#f9f9fb";
export var grayForBorder = "#c9cdd8";
export var green = "#5baf42";
export var greyLightest = "#f2f2f2";
export var info = "#a74af8";
export var lightGray = "#9f9f9f";
export var lighterGray = "#dcdcdc";
export var link = "inherit";
export var mainPurple = "#a74af8";
export var mediaMinWidth = "768px";
export var orange = "#ff8316";
export var primary = "#0093dd";
export var primaryLight = "#eff5fb";
export var red = "#d70505";
export var teal = "#42abaf";
export var text = "var(--text, #5B6070)";
export var white = "#fff";
export var yellow = "#fa0";