import * as React from "react";

// import { Link } from "gatsby"
import { Link, useTranslation, Trans, I18nextContext } from "gatsby-plugin-react-i18next";

import PhoneIcon from "../../images/icons/phone-contacts.svg";
import EmailIcon from "../../images/icons/email-contacts.svg";
import FacebookIcon from "../../images/icons/facebook.svg";
import InstagramIcon from "../../images/icons/instagram.svg";
import LinkedInIcon from "../../images/icons/linkedin.svg";
import YoutubeIcon from "../../images/icons/youtube.svg";
import PoweredByIcon from "../../images/vivacom/powered_by_boleron.svg";
import "@styles/components/Footer.sass";
import { getActiveProductsFromConfig, getFooter, getFooterLogo } from "../../config/activeProducts/helpers";
import ProductsService from "../../services/ProductsService";
import { GATSBY_CONFIGURATION } from "../../config/activeProducts";

import { modifyProductsForFooter } from "../../utils/functions";

const Footer = () => {
  const currentLanguage = React.useContext(I18nextContext).language;

  const { t } = useTranslation();

  const renderProducts = () => {
    const arrayOfSections = [];
    const countOfProductsInSection = GATSBY_CONFIGURATION === "boleron" ? 3 : 2;
    const titleStyle = GATSBY_CONFIGURATION === "boleron" ? "" : "is-bold-family";

    for (let i = 0; i < 3; i++) {
      // temporary workaround before footer redesign  Math.ceil(getActiveProductsFromConfig().length / countOfProductsInSection); i++) {
      const title = (
        <div>
          <h3 className={titleStyle}>{t("Products", "Застраховки")}</h3>
          <svg height="30" width="30">
            <g fill="none">
              <path stroke="white" strokeWidth="6" d="M0 0 l300 0" />
            </g>
          </svg>
        </div>
      );
      const socialMediaSection = (
        <div className="column-with-social-media pb-2">
          <div className="contact-icons-wrapper-footer">
            <a href="https://www.facebook.com/boleron.bg/" target="_blank" rel="noreferrer">
              <FacebookIcon className="contact-icons-footer" />
            </a>
            <a href="https://www.instagram.com/boleron.bg/" target="_blank" rel="noreferrer">
              <InstagramIcon className="contact-icons-footer" />
            </a>
            <a href="https://www.linkedin.com/company/boleron" target="_blank" rel="noreferrer">
              <LinkedInIcon className="contact-icons-footer" />
            </a>
            <a href="https://www.youtube.com/channel/UC-OkJILsTbjN8c6yjUhaPQg" target="_blank" rel="noreferrer">
              <YoutubeIcon className="contact-icons-footer contact-youtube-icon" />
            </a>
          </div>
        </div>
      );

      const activeProducts = GATSBY_CONFIGURATION === "boleron" ? modifyProductsForFooter(getActiveProductsFromConfig()) : getActiveProductsFromConfig();

      const productsHtml = activeProducts?.slice(i * countOfProductsInSection, (i + 1) * countOfProductsInSection)?.map(p => (
        <Link key={p?.id} className="link-in-footer" to={p?.link}>
          {ProductsService.getProductName(p?.id, currentLanguage)}
        </Link>
      ));

      let additionalClass = "";
      if (i === 1 || (i === 2 && getFooter()?.sectionSocialMedia?.disabled)) {
        additionalClass = "column-without-head";
      } else if (i === 2 && !getFooter()?.sectionSocialMedia?.disabled) {
        additionalClass = "insurances-below-social-media";
      }
      arrayOfSections.push(
        <div key={`footer-section-${i}`} className={`column ${i === 0 && GATSBY_CONFIGURATION === "vivacom" ? "ml-8" : ""}`}>
          {i === 0 && title}
          {i === 2 && !getFooter()?.sectionSocialMedia?.disabled && socialMediaSection}
          <div className={`column-specification ${additionalClass}`}>{productsHtml}</div>
        </div>
      );
    }
    return arrayOfSections;
  };
  const Logo = getFooterLogo();

  return (
    <footer className="footer-new">
      <div className="container-main">
        <div className="columns">
          <div className="logo-with-info is-one-third">
            <div className="column column-specification first-column">
              <div className="navbar-brand">
                {" "}
                <Link className="navbar-item is-block" to="/">
                  {Logo}
                  {!getFooter()?.logoText?.disabled && (
                    <div
                      className="is-uppercase text-logo-footer"
                      style={{
                        letterSpacing: { bg: "1px", en: "2.8px", ru: "3.5px" }[currentLanguage],
                        paddingLeft: 8,
                        textAlign: "center",
                        fontSize: 12
                      }}
                    >
                      {t("online_insurance", "Онлайн застраховки")}
                    </div>
                  )}
                </Link>
              </div>
              {/*{!getFooter()?.hr?.disabled && <hr className="is-hidden-desktop" />}*/}
              <div className="contact-info-wrapper">
                {getFooter()?.phone?.text && (
                  <div className="contact-info">
                    {!getFooter()?.phone?.isIconDissabled && (
                      <span>
                        <PhoneIcon className="icon-footer" />
                      </span>
                    )}
                    {getFooter()?.phone?.link && (
                      <a className="email-footer" href={`tel:${getFooter()?.phone?.link}`}>
                        <span>{getFooter()?.phone?.text}</span>
                      </a>
                    )}
                    {!getFooter()?.phone?.link && (
                      <a className="email-footer" href={`tel:${getFooter()?.phone?.text}`}>
                        <span>{getFooter()?.phone?.text}</span>
                      </a>
                    )}
                  </div>
                )}
                <div className="contact-info">
                  {!getFooter()?.email?.isIconDissabled && (
                    <span>
                      <EmailIcon className="icon-footer" />
                    </span>
                  )}
                  <a className="email-footer" href={`mailto:${getFooter()?.email?.text}`}>
                    {getFooter()?.email?.text}
                  </a>
                </div>
              </div>
            </div>

            <div className="column column-specification title-with-underline">
              {!getFooter()?.sectionMenu?.disabled && (
                <>
                  <div>
                    <h3>{t("Menu", "Меню")}</h3>
                    <svg height="30" width="30">
                      <g fill="none">
                        <path stroke="white" strokeWidth="6" d="M0 0 l300 0" />
                      </g>
                    </svg>
                  </div>
                  <Link className="link-in-footer-without-pseudo" to="/about-us/">
                    {t("AboutUs", "За Нас")}
                  </Link>
                  <Link className="link-in-footer-without-pseudo" to="/documents/">
                    {t("Documents", "Документи")}
                  </Link>
                  <Link className="link-in-footer-without-pseudo" to="/contacts/">
                    {t("Contacts", "Контакти")}
                  </Link>
                </>
              )}
              {getFooter()?.sectionMenu?.disabled && (
                <>
                  <div>
                    <h3 className="is-bold-family">{t("Menu", "Меню")}</h3>
                    <svg height="30" width="30">
                      <g fill="none">
                        <path stroke="white" strokeWidth="6" d="M0 0 l300 0" />
                      </g>
                    </svg>
                  </div>
                  <Link className="link-in-footer-without-pseudo" to="/documents/">
                    {t("PlatformDocuments", "Полезна информация")}
                  </Link>
                </>
              )}
            </div>
          </div>
          {renderProducts()}
        </div>
        {!getFooter()?.sectionMessage?.disabled && (
          <div className="footer-message-container">
            <Trans i18nKey="Footer Address" />
          </div>
        )}
        {!getFooter()?.sectionSocialMedia?.disabled && (
          <div className="mobile-social-media-icons">
            <div className="column-with-social-media">
              <div className="contact-icons-wrapper-footer">
                <a href="https://www.facebook.com/boleron.bg/" target="_blank" rel="noreferrer">
                  <FacebookIcon className="contact-icons-footer" />
                </a>
                <a href="https://www.instagram.com/boleron.bg/" target="_blank" rel="noreferrer">
                  <InstagramIcon className="contact-icons-footer" />
                </a>
                <a href="https://www.linkedin.com/company/boleron" target="_blank" rel="noreferrer">
                  <LinkedInIcon className="contact-icons-footer" />
                </a>
                <a href="https://www.youtube.com/channel/UC-OkJILsTbjN8c6yjUhaPQg" target="_blank" rel="noreferrer">
                  <YoutubeIcon className="contact-icons-footer contact-youtube-icon" />
                </a>
              </div>
            </div>
          </div>
        )}
        {!getFooter()?.hr?.disabled && (
          <div className={`${getFooter()?.footerText?.isCentered ? "has-text-centered" : ""}  ${getFooter()?.footerText?.paddingStyles}`}>
            Застрахователните продукти и услуги се предоставят чрез посредничеството на Виваком България ЕАД, ЕИК: 831642181 - застрахователен брокер с номер на удостоверение
            552-ЗБ от 18.10.2022 г. и Болерон Консултинг ЕООД, ЕИК: 102764975 - застрахователен брокер с номер на удостоверение 721-3Б от 01.12.2020 г., издадени от Комисията по
            финансов надзор.
          </div>
        )}
      </div>

      {!getFooter()?.hr?.disabled && <hr style={{ maginTop: 0 }} />}
      {!getFooter()?.hr?.disabled && (
        <div className="container-main">
          <div className="powered-by-boleron is-flex is-align-items-center">
            <a href="https://www.boleron.bg">
              <PoweredByIcon className="icon-footer ml-2 mr-2" /> <span>Powered by Boleron</span>
            </a>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
