import { makePersistable } from "mobx-persist-store";
import { makeAutoObservable } from "mobx";
import _set from "lodash.set";

export class StashedProductDataStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "StashedFlowData",
      properties: ["stashedFlowDataCI", "stashedFlowDataCHCL", "stashedFlowDataLFE", "stashedFlowDataHWP", "stashedFlowDataHCL"],
      storage: typeof document !== "undefined" ? window.localStorage : null
    });
  }

  stashedFlowDataCI = "";

  stashedFlowDataCHCL = "";

  stashedFlowDataLFE = "";

  stashedFlowDataHWP = "";

  stashedFlowDataHCL = "";

  set(name, value) {
    this[name] = value;
  }

  setDeep(propertyPath, value) {
    _set(this, propertyPath, value);
  }

  reset() {
    this.stashedFlowDataCI = "";
  }
}
const stashedProductData = new StashedProductDataStore();
export default stashedProductData;
